import styled from 'styled-components';

const AnimatedCoderStyles = styled.div`
    .row-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-top: 110px;
    }
    .column-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 80%;
        margin: auto;
    }

    .animatedcoder-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        position: relative;
        height: 100vh;
        width: 100vw;
    }
    .baldvin {
        color: #645beb;
        text-shadow: 2px 2px black;
        font-size: clamp(20px,10vw,50px);
        font-weight: 700;
        margin-bottom: 50px;
        z-index: 10;
        position: relative;
        top: clamp(-20px,10vw,-50px);
        left: 0%;
    }
    .baldvin.yellow {
        color: yellow;
    }
    .animation-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 80%;
        max-width: 1000px;
        z-index: 1;
    }
    .robot-animation-wrapper {
        position: absolute;
        min-width: 500px;
        max-width: 800px;
        bottom: 50vh;
        width: 100%;
        will-change: opacity, transform;
    }
    
    .coder-animation-wrapper {
        min-width: 350px;
        max-width: 500px;
        width: 80%;
        position: absolute;
        bottom: 20vh;
        padding-left: 5vw;
        will-change: opacity, transform;
    }
    @media screen and (max-width: 699px) {
        .baldvin {
            top: -5vh;
        }
        .animation-box {
            position: relative;
            height: 80%;
        }
        .robot-animation-wrapper {
            bottom: 65vh;
        }
        .coder-animation-wrapper {
            position: relative;
        } 
    }
    @media screen and (max-width: 499px) {
        .baldvin {
            position: absolute;
            inset: 0px auto;
        }
        .animation-box {
            position: absolute;
            top: 5vh;
        }
        .robot-animation-wrapper {
            height: fit-content;
            width: auto;
            margin: 0vh auto;
            top: -8vmin;
        }
        .coder-animation-wrapper {
            bottom: 5vmax;
        } 
    }

    .robot,
    .coder {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0vh;
        left: 0vw;
        z-index: 100;
        opacity: 1;
        will-change: opacity, transform;
    }
    .display-none {
        z-index: -100;
        position: absolute;
        opacity: 0.01;
        will-change: opacity, transform;
    }
    
    .sun {
        width: 10vw;
        height: 10vw;
        min-width: 70px;
        min-height: 70px;
        background: #ff0 !important;
        background: radial-gradient(ellipse at center, #f90 0%,#fc0 60%,#ff0 100%) !important;
        border-radius: 100%;
        position: absolute;
        z-index: 0;
        bottom: 10vh;
        right: -10vw;
        transform-origin: -40vw 10vw;
        animation: suntrack 24s infinite backwards linear, sunpulse 2s alternate infinite; 
    }
    .moon { 
        background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/4273/moon8.png) !important;
        width: 8vw;
        height: 8vw;
        min-width: 50px;
        min-height: 50px;
        position: absolute;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 90% 90%;
        border-radius: 100%;
        bottom: 10vh;
        right: -10vw;
        transform-origin: -40vw 0vw;
        animation: moontrack 24s infinite backwards linear;
        animation-delay: 12s;
    } 

    @keyframes suntrack {	
        from { 
            transform: rotate(-.85turn);
        }
        to {
            transform: rotate(.15turn);
        }
    }
    @keyframes moontrack {	
        from { transform: rotate(-.85turn); }
        to { transform: rotate(.15turn); }
    }
    @keyframes sunpulse { 
        from { box-shadow: 0 0 100px #ff0, 0 0 100px #ff0; }
        to { box-shadow: 0 0 50px #ff0, 0 0 75px #ff0;  }
    }
    .sun-moon-box { 
        display: flex;
        margin: auto; 
        background-color: #000 !important;
        background-size: cover;
        position: absolute;
        /* height: 100vh; */
        height: calc(100vh + 110px);
        width: 100vw;
        overflow: hidden;
        z-index: 0;
    }
    .screen-container {                
        position: relative;
        height: clamp(300px,50vmin,640px);
        width: clamp(260px,80vw,800px);
        border-radius: 15px;
        opacity: 1;
        z-index: -1;
        border-radius: 15px;
        overflow: hidden;
        margin: 10vh auto;
    }
    
    .sun-moon-container { 
        margin: 0; 
        overflow: hidden;
        z-index: 0;
        opacity: 0.8;
        border-radius: 10px;
    }
`;

export default AnimatedCoderStyles;