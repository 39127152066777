import React from "react";
import "./App.css";
import SiteContent from "./containers/SiteContent";
function App() {
  return (
    <div>
      <SiteContent />
    </div>
  );
}

export default App;