const illustration = {
  animated: true
};

const greeting = {
  username: "Baldvin B. Oddsson",
  title: "Hello, I am Baldvin,",
  subTitle: `
    I'm a creative Web Developer with ideas for all stages of the project ahead. 
    
    My strength is in Frontend development, creating awesome Interfaces with smooth user experiences. 
    
    Although my main focus & passion is UI/UX, I have experience with APIs, Authentication, Databases and creating Full-Stack Projects, Web and Mobile applications. My current main tools are React and related tools. I also have experience using Next.js and some other cool libraries and frameworks.
  `,
  resumeLink:
    "https://drive.google.com/file/d/1ofFdKF_mqscH8WvXkSObnVvC9kK7Ldlu/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true
};


// Skills Section
const mostSkillsSection = {
  title: "Dev Skills",
  subTitle: `
      Front end design and development is what 
      I know and like best. HTML, CSS, JS and Javascript 
      are the tools at the core of my projects and have experience with the main
      related techs & tools like NodeJS, Next.Js, Nest.js, GraphQL, Redis, MongoDB...
    `,
  skills: [
  ],
  softwareSkills: [
    { skillName: "HTML5", fontAwesomeClassname: "fab fa-html5" },
    { skillName: "VScode", iconfy: "akar-icons:vscode-fill" },
    { skillName: "Material-UI", iconfy: "simple-icons:mui" },
    { skillName: "JSON", iconfy: "bxs:file-json" },
    { skillName: "CSS3", fontAwesomeClassname: "fab fa-css3-alt" },
    { skillName: "JavaScript", fontAwesomeClassname: "fab fa-js" },
    { skillName: "React JS", fontAwesomeClassname: "fab fa-react" },
    { skillName: "npm", fontAwesomeClassname: "fab fa-npm" },
    { skillName: "github", fontAwesomeClassname: "fab fa-github-square" },
    { skillName: "styled-components", iconfy: "simple-icons:styledcomponents" }
  ],
  display: true // Set false to hide this section, defaults to true
};
const DevSkills = {
  title: "Responsive Design",
  subTitle: "When creating websites and apps I strive to make everything work equally smoothly on desktop and mobile, from small to large screens.",
  skills: [
  ],

  softwareSkills: [
    { skillName: "Redux", iconfy: "cib:redux" },
    { skillName: "yarn", fontAwesomeClassname: "fab fa-yarn" },
    { skillName: "terminal", iconfy: "codicon:terminal-cmd" },
    { skillName: "Bootstrap", iconfy: "cib:bootstrap" },
    { skillName: "cmd", iconfy: "clarity:command-solid" },
    { skillName: "Next JS", fontAwesomeClassname: "cib-next-js" },
    { skillName: "git", fontAwesomeClassname: "fab fa-git" },
    { skillName: "node.js", fontAwesomeClassname: "fab fa-node" },
    { skillName: "WordPress", fontAwesomeClassname: "fab fa-wordpress" },
    { skillName: "Paint.net", iconfy: "fa:paint-brush" }
  ],
  display: false
};

const moreSkillsSection = {
  title: "Responsive Design",
  subTitle: `
    When creating websites and apps I strive to make all UI components work 
    as smoothly on mobile as desktop, with easy, fast, fun and good looking 
    user experience on screens from small to large. 
    Progressive Web Applications ( PWA ) are cool and that's how I'm implementing my projects right now.
  `,
  skills: [
  ],

  softwareSkills: [
    { skillName: "Redux", iconfy: "cib:redux" },
    { skillName: "yarn", fontAwesomeClassname: "fab fa-yarn" },
    { skillName: "terminal", iconfy: "codicon:terminal-cmd" },
    { skillName: "Bootstrap", iconfy: "cib:bootstrap" },
    { skillName: "cmd", iconfy: "clarity:command-solid" },
    { skillName: "Next JS", fontAwesomeClassname: "cib-next-js" },
    { skillName: "git", fontAwesomeClassname: "fab fa-git" },
    { skillName: "node.js", fontAwesomeClassname: "fab fa-node" },
    { skillName: "WordPress", fontAwesomeClassname: "fab fa-wordpress" },
    { skillName: "Paint.net", iconfy: "fa:paint-brush" }
  ],
  display: false
};
const newSkillsSection = {
  title: "All Stages",
  subTitle: `
    From first brainstorm for a project to designing the user interface,
    thinking up & developing fitting userflow and then deliver great products 
    is my ambition and what I think about large part of each day. 
    I think great looking user interfaces should go in hand with
    straight forward, easy to understand user experience and be fun or enjoyable 
    experience. 
  `,
  skills: [
  ],

  softwareSkills: [
    { skillName: "SASS", fontAwesomeClassname: "fab fa-sass" },
    { skillName: "PWA", iconfy: "simple-icons:pwa" },
    { skillName: "Astro JS", fontAwesomeClassname: "iconify-inline simple-icons:astro" },
    { skillName: "Figma", fontAwesomeClassname: "fab fa-figma" },
    { skillName: "gitlab", fontAwesomeClassname: "fab fa-gitlab" },
    { skillName: "PostgreSQL", iconfy: "cib:postgresql" },
    { skillName: "Inkscape", iconfy: "cib:inkscape" },
    { skillName: "React-Lottie", iconfy: "uil:lottiefiles-alt" },
    { skillName: "firebase", fontAwesomeClassname: "fas fa-fire" },
    { skillName: "MongoDB", iconfy: "bxl:mongodb"}
  ],
  display: false // Set false to hide this section, defaults to true
};

// Education Section
const educationInfo = {
  display: true,
  schools: [
    {
      schoolName: "University of Iceland",
      logo: require("./assets/images/hiLogoA.png"),
      subHeader: "Bachelor of Science in Electrical & Computer Engineering",
      duration: "September 2005 - June 2008",
      desc: "",
      darkborder: false,
      descBullets: [
      ]
    },
    {
      schoolName: "Menntaskólinn í Kópavogi",
      logo: require("./assets/images/mkLogo.png"),
      subHeader: "Stúdent af náttúrufræðibraut",
      duration: "September 2000 - June 2004",
      desc: "",
      darkborder: false,
      descBullets: []
    },
    {
      schoolName: "University of Iceland",
      logo: require("./assets/images/hiLogoB.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2018 - 2020",
      desc: "",
      darkborder: false,
      descBullets: [
      ]
    }
  ]
};

const workInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "University of Iceland",
      logo: require("./assets/images/hiLogoA.png"),
      subHeader: "Bachelor of Science in Electrical & Computer Engineering",
      duration: "September 2005 - June 2008",
      desc: "",
      descBullets: [
      ]
    },
    {
      schoolName: "Menntaskólinn í Kópavogi",
      logo: require("./assets/images/mkLogo.png"),
      subHeader: "Stúdent af náttúrufræðibraut",
      duration: "September 2000 - June 2004",
      desc: "",
      descBullets: []
    },
    {
      schoolName: "University of Iceland",
      logo: require("./assets/images/hiLogoB.png"),
      subHeader: "Bachelor of Science in Computer Science",
      duration: "September 2018 - 2020",
      desc: "",
      descBullets: [
      ]
    }
  ]
};

// Demo Portfolio Projects
const demoProjects = {
  title: "Portfolio Preview",
  subtitle: `
    Here are just a couple snapshots of many more projects I have made and to be added in the next days.
    
  `,
  projects: [
    {
      image: require("./assets/images/card-images/WordleA.jpg"),
      image03: require("./assets/images/card-images/WordleB.jpg"),
      projectName: "Orðla++",
      projectDesc: "The fun & addictive game of 'Wordle' taken steps further...",
      footerLink: [
        {
          name: "Live Demo Here (Very Soon)",
          url: ""
        }
      ]
    },
    {
      image: require("./assets/images/card-images/CARD_Magic-Memory.png"),
      image03: require("./assets/images/card-images/MemPixB.jpg"),
      projectName: "MemPix",
      projectDesc: "Good looking version of the simply fun memory card game.",
      footerLink: [
        {
          name: "Live Demo Here (Very Soon)",
          url: ""
        }
      ]
    },
    {
      image: require("./assets/images/card-images/CARD_Magic-Memory.png"),
      image03: require("./assets/images/card-images/TicTacToeB.jpg"),
      projectName: "Tic Tac Toe",
      projectDesc: "The Good Old 3-in-a-Row game or Xs & Os.",
      footerLink: [
        {
          name: "Live Demo Here (Very Soon)",
          url: ""
        }
      ]
    },
    {
      image: require("./assets/images/card-images/Spurter00.jpg"),
      image03: require("./assets/images/card-images/SpurterB.jpg"),
      projectName: "Spurter",
      projectDesc: "Gamefied learning for students...",
      footerLink: [
        {
          name: "Live Demo Here (Very Soon)",
          url: ""
        }
        //  area for extra buttons here.
      ]
    },
    {
      image: require("./assets/images/card-images/CARD_Magic-Memory.png"),
      image03: require("./assets/images/card-images/GenArt.jpg"),
      projectName: "Generative Art",
      projectDesc: "",
      footerLink: [
        {
          name: "Live Demo Here (Very Soon)",
          url: ""
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: "Contact Me",
  subtitle:
   [""],
  number: "",
  email_address: [
    "baldvin@bald.vin"
  ]
};

export {
  illustration,
  greeting,
  mostSkillsSection,
  moreSkillsSection,
  newSkillsSection,
  educationInfo,
  workInfo,
  demoProjects,
  contactInfo,
};
