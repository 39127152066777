import React, {useContext} from "react";
import {Fade} from "react-reveal";
import "./Greeting.css";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import MeDesignin from "../../assets/lottie/webDesigner01.json";

import { ButtonGreeting } from "../../components/button/Button";

import { greeting} from "../../portfolio";
import StyleContext from "../../contexts/StyleContext";

export default function Greeting() {
  const {isDark} = useContext(StyleContext);
  if (!greeting.displayGreeting) {
    return null;
  }
  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="Me">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1
                className={isDark ? "dark-mode greeting-text" : "greeting-text"}
              >
                {" "}
                {greeting.title}{" "}
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {/* {greeting.subTitle} */}
                I'm a creative Web Developer with ideas for all stages of the project ahead.
              </p>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {/* {greeting.subTitle} */}
                My strength is in Frontend development, creating awesome Interfaces with smooth user experiences.
              </p>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                Although my main focus & passion is UI/UX, I have experience with APIs, Authentication, Databases and creating Full-Stack Projects, Web and Mobile applications. My current main tools are React and related tools. I also have experience using Next.js and some other cool libraries and frameworks.
              </p>
              <div className="button-greeting-div" >
                <ButtonGreeting className={isDark ? "dark-mode-button-greeting" : "button-greeting"} text="Contact me" href="#contact" />
              </div>
            </div>
          </div>
          <div className="greeting-image-div lottie-designer" id={isDark ? "dark-lottie-designer" : "lottie-designer"}>
            <DisplayLottie 
              animationData={MeDesignin}
              isPaused={true}
              // id="lottie-designer"
            />
          </div>
        </div>
      </div>
    </Fade>
  );
}
