import styled from "styled-components";

const SiteContentStyles = styled.div`
    .site-wrapper {
        height: 100vh;
        width: calc(100vw + 2px);
        overflow-x: hidden;
        overflow-y: scroll;
        box-sizing: border-box;
        border-left: 15px solid rgb(23, 28, 40) !important;
        scroll-behavior: smooth;
        position: relative;
        left: -1px;
    }
    .dark-mode {
        background-color: #171c28;
        color: white;
        transition: "0.1s";
    }
    .section-container {
        margin-right: -10px;
        max-width: 1500px;
        margin: auto;
        padding-top: 100px;
    }
    @media (max-width: 500px) {
        .section-container {
            padding-top: 55px;
        }
        .site-wrapper {
            border-left: 0px;
        }
    }
    .site-wrapper::-webkit-scrollbar {
        width: 15px;
    }
    .site-wrapper::-webkit-scrollbar-track {
        background: rgb(23, 28, 40);
    }
    .site-wrapper::-webkit-scrollbar-thumb {
        background: #645beb;
        border: 3px solid black;
        height: 30vh;
    }
    .dark-mode.site-wrapper {
        border-left: 15px solid black !important;
    }
    .dark-mode.site-wrapper::-webkit-scrollbar-thumb {
        background: yellow;
        border: 3px solid black;
        height: 30vh;
    }
    .dark-mode.site-wrapper::-webkit-scrollbar-track {
        background: black;
    }
`;

export default SiteContentStyles;