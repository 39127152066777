import React from "react";
import {useContext} from "react";
import StyleContext from "../../contexts/StyleContext";

import DisplayLottie from "../displayLottie/DisplayLottie";

import Robot_Light from '../../assets/lottie/Robot_Light.json';
import Robot_Dark from '../../assets/lottie/Robot_Dark.json';

const RobotAnimation = () => {
    const {isDark} = useContext(StyleContext);

  return (
    <div className="robot-animation-wrapper">
        <div className={`${isDark ? "robot display-none" : "robot"}`} id="robot-light">
            <DisplayLottie animationData={Robot_Light} />
        </div>
        <div className={`${isDark ? "robot" : "robot display-none"}`} id="robot-dark">
            <DisplayLottie animationData={Robot_Dark} />
        </div>
    </div>
  );
}

export default RobotAnimation;
