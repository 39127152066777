import React, {useContext} from "react";
import "./Contact.css";
import {illustration, contactInfo} from "../../portfolio";
import {Fade} from "react-reveal";

import Email_Light from "../../assets/lottie/Email_Light";
import Email_Dark from "../../assets/lottie/Email_Dark";
import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import StyleContext from "../../contexts/StyleContext";

export default function Contact() {
  const {isDark} = useContext(StyleContext);
  return (
    <>
      <Fade bottom duration={1000} distance="20px">
        <div className="contact-main contact-margin-top" id="contact">
        
          <div className={ isDark ? "dark-mode contact-div-main" : "contact-div-main" }>
            
            <div className={ isDark ? "dark-mode contact-header" : "contact-header" }>
              {/* <h1 className={ isDark ? "dark-mode contact-header-dark-mode-heading-contact-title" : "heading contact-title" }> */}
              <h1 className={ isDark ? "dark-mode heading contact-title" : "heading contact-title" }>
                {contactInfo.title}
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode contact-subtitle"
                    : "subTitle contact-subtitle"
                }
              >
                {contactInfo.subtitle}
              </p>
              <div className={ isDark ? "dark-mode contact-text-div" : "contact-text-div" } >
                {contactInfo.number && (
                  <>
                    <a
                      className={ isDark ? "dark-mode-contact-detail" : "contact-detail" }
                      href={"tel:" + contactInfo.number}
                    >
                      {contactInfo.number}
                    </a>
                    <br />
                    <br />
                  </>
                )}
                <a
                  className={ isDark ? "dark-mode-contact-detail-email" : "contact-detail-email" }
                  href={"mailto:" + contactInfo.email_address}
                >
                  {contactInfo.email_address}
                </a>
                <br />
                <br />
              </div>
            </div>
            <div className="contact-image-div" >
              {illustration.animated ? (
                <DisplayLottie animationData={isDark ? Email_Dark : Email_Light} className="lottie" />
              ) : (
                <img
                  alt="Man working"
                  src={ isDark ? require("../../assets/images/contactMailDark.svg") : require("../../assets/images/contactMailDark.svg") }
                  className="contact-image"
                ></img>
              )}
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
}
