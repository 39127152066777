import React, {useState, useContext} from "react";
import StyleContext from "../../contexts/StyleContext";
import "./ToggleSwitch.css";

const ToggleSwitch = () => {
  const {isDark} = useContext(StyleContext);
  const [isChecked, setChecked] = useState(isDark);
  const styleContext = useContext(StyleContext);

  return (
    <div className="switch-container">
      <label className="switch">
        <input
          type="checkbox"
          checked={isDark}
          onChange={() => {
            styleContext.changeTheme();
            setChecked(!isChecked);
          }}
        />
        <span className="slider round"></span>
      </label>
    </div>
  );
};
export default ToggleSwitch;
