import React, {useContext, useState} from "react";
import "./Header.css";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import {
  greeting,
  educationInfo,
  workInfo,
  mostSkillsSection,
  demoProjects
} from "../../portfolio";

function Header() {
  const [ menuSelected, setMenuSelected ] = useState('');
  const {isDark} = useContext(StyleContext);
  const viewEducation = educationInfo.display;
  const viewWork = workInfo.display;
  const viewSkills = mostSkillsSection.display;
  const viewProjects = demoProjects.display;

  return (
      <header className={isDark ? "dark-menu-header dark-menu header" : "header"}>
        <a className="logo">
          <span className={isDark ? "yellow-color" : "blue-color"}> &lt;</span>
          <span className={isDark ? "logo-name yellow-color" : "logo-name blue-color"}>{greeting.username}</span>
          <span className={isDark ? "yellow-color" : "blue-color"}>/&gt;</span>
        </a>

        <input className="menu-btn" type="checkbox" id="menu-btn" />

        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{color: "white"}}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>

        <ul className={isDark ? "dark-menu menu" : "menu"}>
          <li className={`${menuSelected==="Me" ? "menu-selected about-me" : "about-me"}`}>
            <a href="#Me" onClick={() => setMenuSelected("Me")} >Me</a>
          </li>
          {viewSkills && (
            <li className={`${menuSelected==="skills" ? "menu-selected" : ""}`}>
              <a href="#skills" onClick={() => setMenuSelected("skills")} >Skills</a>
            </li>
          )}
          {viewEducation && (
            <li className={`${menuSelected==="education" ? "menu-selected" : ""}`}>
              <a href="#education" onClick={() => setMenuSelected("education")}>Education</a>
            </li>
          )}
          {viewWork && (
            <li className={`${menuSelected==="work" ? "menu-selected" : ""}`}>
              <a href="#work" onClick={() => setMenuSelected("work")} >Work</a>
            </li>
          )}
          {viewProjects && (
            <li className={`${menuSelected==="Portfolio" ? "menu-selected" : ""}`}>
              <a href="#projects" onClick={() => setMenuSelected("Portfolio")}>Portfolio</a>
            </li>
          )}        
          <li className={`${menuSelected==="contact" ? "menu-selected" : ""}`}>
            <a href="#contact" onClick={() => setMenuSelected("contact")}>Contact</a>
          </li>
          <li>
            <ToggleSwitch />
          </li>
        </ul>
      </header>
  );
}
export default Header;
