import React, { useEffect, useState } from "react";
import "./ScrollToTopButton.css";

export default function ScrollToTopButton( {isDark} ) {
  const [showFinger, setShowFinger ] = useState(false);

  useEffect(() => {
    if(document.querySelector(".site-wrapper").scrollTop > 55) {
      setShowFinger(true);
    }
  }, []);

  function TopEvent() {
    document.querySelector(".site-wrapper").scrollTop = 0;
  }
  // When the user scrolls down 55px from the top of the document, show the button
  function scrollFunction() {
    if ( showFinger ) {
      document.getElementById("topButton").style.visibility = "visible";
    } else {
      document.getElementById("topButton").style.visibility = "hidden";
    }
  }
  window.onscroll = () => scrollFunction();
  window.onload   = () => scrollFunction(); 
  return (
    <button className={isDark ? "topButton-yellow" : "topButton-blue"} onClick={TopEvent} id="topButton" title="Go to top">
      <i className="fas fa-hand-point-up" ></i>
    </button>
  );
}
