import React, {useContext} from "react";
import StyleContext from "../../contexts/StyleContext";

import DisplayLottie from "../../components/displayLottie/DisplayLottie";
import {mostSkillsSection, moreSkillsSection, newSkillsSection} from "../../portfolio";
import {Fade} from "react-reveal";

import Design2uxYellow from "../../assets/lottie/Design2uxYellow.json"
import Design2uxBlue from "../../assets/lottie/Design2uxBlue.json"
import ResponsiveDesign from "../../assets/lottie/ResponsiveDesign.json";
import ResponsiveBlue from "../../assets/lottie/ResponsiveBlue.json";
import ResponsiveYellow from "../../assets/lottie/ResponsiveYellow.json";
import SkillsCodingYellow from "../../assets/lottie/SkillsCodingYellow.json"
import SkillsCodingBlue from "../../assets/lottie/SkillsCodingBlue.json"


import AnimatedIconBG from "../../components/AnimatedIconBG/AnimatedIconBG";
import DevSkillsStyles from "./DevSkillsStyles";

export default function DevSkills() {
  const {isDark} = useContext(StyleContext);
  if (!mostSkillsSection.display) {
    return null;
  }
  return (
    <DevSkillsStyles>
    <div className={isDark ? "dark-mode main" : "main"} id="skills">
      <AnimatedIconBG />
      <div className="skills-main-div">
        <Fade right duration={1000}>
          <div className="skills-text-div">
            <h1 className={isDark ? "dark-mode skills-heading" : "skills-heading"} >
              {mostSkillsSection.title}{" "}
            </h1>
            <p className={ isDark ? "dark-mode subTitle skills-text-subtitle" : "subTitle skills-text-subtitle" } >
              {mostSkillsSection.subTitle}
            </p>
          </div>
        </Fade>
        <Fade left duration={1000}>
          <div className="skills-image-div">
            <div className={isDark ? "show" : "hide"} >
              <DisplayLottie animationData={SkillsCodingYellow} />
            </div>
            <div className={isDark ? "hide" : "show"} >
              <DisplayLottie animationData={SkillsCodingBlue} />
            </div>
          </div>
        </Fade>
      </div>
      
      <div className="skills-main-div">
        <Fade left duration={1000}>
            {/* <div className="skills-image-div">
              <DisplayLottie animationData={ResponsiveDesign} />
            </div> */}
            <div className="skills-image-div">
              <div className={isDark ? "show" : "hide"} style={{ transform:"translate3d(0px, -25%, 0px)" }}>
                <DisplayLottie animationData={ResponsiveYellow} />
              </div>
              <div className={isDark ? "hide" : "show"} style={{ transform:"translate3d(0px, -25%, 0px)" }}>
                <DisplayLottie animationData={ResponsiveBlue} />
              </div>
            </div>
          </Fade>
        <Fade right duration={1000}>
          <div className="skills-text-div">
            <h1 className={isDark ? "dark-mode more-skills-heading" : "more-skills-heading"} >
              {moreSkillsSection.title}{" "}
            </h1>
            <p className={ isDark ? "dark-mode subTitle skills-text-subtitle" : "subTitle skills-text-subtitle" } >
              {moreSkillsSection.subTitle}
            </p>
          </div>
        </Fade>
      </div>
      <div className="skills-main-div">
        
        <Fade right duration={1000}>
          <div className="skills-text-div">
            <h1 className={isDark ? "dark-mode skills-heading" : "skills-heading"} >
              {newSkillsSection.title}{" "}
            </h1>
            <p className={ isDark ? "dark-mode skills-text-subtitle" : "skills-text-subtitle" } >
              {newSkillsSection.subTitle}
            </p>
          </div>
        </Fade>
        <Fade left duration={1000}>
          {/* <div className="skills-image-div">
            <DisplayLottie animationData={isDark ? Design2uxYellow : Design2uxBlue} />
          </div> */}
          <div className="skills-image-div">
            <div className={isDark ? "show" : "hide"} >
              <DisplayLottie animationData={Design2uxYellow} />
            </div>
            <div className={isDark ? "hide" : "show"} >
              <DisplayLottie animationData={Design2uxBlue} />
            </div>
          </div>
        </Fade>
      </div>
      <p className={ isDark ? "dark-mode bottomText" : "bottomText" } >
        Although Frontend Creation, Designing and Programming UI/UX is my focus, I work with APIs, Databases and can work 'full-stack'.
      </p>
    </div>
    </DevSkillsStyles>
  );
}
