import React from "react";
import {useContext} from "react";
import StyleContext from "../../contexts/StyleContext";

import SunRise from "./SunRise";

import starfieldBlue from '../../assets/backgrounds/starfield-blue.jpg';
import nightScreen from '../../assets/backgrounds/nightstars.jpg';
import dayScreen from '../../assets/backgrounds/dayclouds.jpg';

import RobotAnimation from "./RobotAnimation";
import CoderAnimation from "./CoderAnimation";

import AnimatedCoderStyles from "./AnimatedCoderStyles";

const AnimatedCoder = () => {
    const {isDark} = useContext(StyleContext);

    const SunriseContainer = () => {
        return (
            <div className={`${isDark ? "sun-moon-box stars-dark" : "sun-moon-box stars-blue"}`} >
                <img 
                    src={starfieldBlue}
                    style={{ position: "absolute", width: "100%", height: "100%",top: "0vh", left: "0vw", zIndex: "-3", opacity:`${isDark ? 0.5 : 1}` }}
                />
                <div className={`${isDark ? "screen-container yellow-border dark-bg" : "screen-container blue-bg"}`} >
                    <div className="sun-moon-container">
                        <div className="sun-moon-background" style={{ position: "absolute", width: "100%", height: "100%", background: "black" }} />
                        <img 
                            src={dayScreen} 
                            style={{ position: "absolute", width: "100%", height: "100%", opacity:`${isDark ? 0 : 0.75}` }}
                        />
                        <img 
                            src={nightScreen} 
                            style={{ position: "absolute", width: "100%", height: "100%", opacity:`${isDark ? 0.75 : 0}` }}
                        />
                        <SunRise />
                    </div>
                </div>
            </div>
        );
    }

  return (
      <AnimatedCoderStyles>
          <div className="row-wrap">
            <div className="column-wrap">
                <div className="animatedcoder-box">
                    <h1 className={`${isDark ? "baldvin yellow" : "baldvin"}`}>bald.vin</h1>
                    <div className="animation-box">
                        <RobotAnimation />
                        <CoderAnimation />
                    </div>

                    <SunriseContainer />                    
                </div>
            </div>
        </div>
      </AnimatedCoderStyles>
  );
}

export default AnimatedCoder;
