import React from 'react'
import {useContext} from "react";
import StyleContext from "../../contexts/StyleContext";

import newcard01 from '../../assets/images/new-testcards/newtestcard01.png';
import newcard02 from '../../assets/images/new-testcards/newtestcard02.png';
import newcard03 from '../../assets/images/new-testcards/newtestcard03.png';
import newcard04 from '../../assets/images/new-testcards/newtestcard04.png';
import newcard05 from '../../assets/images/new-testcards/newtestcard05.png';
import newcard06 from '../../assets/images/new-testcards/newtestcard06.png';
import newcard07 from '../../assets/images/new-testcards/newtestcard07.png';
import TestAvatar from '../../assets/images/TestAvatar.jpg';
import Me from '../../assets/images/Me.jpg';

import FlipSliderStyles from './FlipSliderStyles';

function FlipSlider() {
    const {isDark} = useContext(StyleContext);

    const FlipMe = () => {
      return (
        <div className = { isDark ? "dark-mode flip-card" : "flip-card" }>
            <div className = "flip-card-inner">
                <div className = { isDark ? "dark-mode flip-card-front side" : "flip-card-front side" }>
                    <img src={Me} alt="Avatar" className='.img' />
                </div>
                <div className = { isDark ? "dark-mode flip-card-back side" : "flip-card-back side" }>
                    <div className = "flip-card-back-content" id={isDark ? "dark-text" : null}>
                        <div>
                            <h1><b>Baldvin</b></h1>
                            <h3>Web Developer</h3>
                            <p>Designer & Coder</p>
                        </div>
                        <div>
                            <p>A dude you want</p>
                            <p><b>on your team</b></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      );
    }
    
    const FlipCard = ({frontside, backside, borderFront, borderBack}) => {
        return (
          <div className = "flip-card">
              <div className = "flip-card-inner">
                  <div className = { isDark ? "dark-mode flip-card-front side" : "flip-card-front side" }>
                    <img src={frontside} alt="" id={borderFront ? "border" : null}/>
                  </div>
                  <div className = { isDark ? "dark-mode flip-card-back side" : "flip-card-back side" }>
                    <img src={backside} alt="" id={borderBack ? "border" : null}/>
                  </div>
              </div>
          </div>
        );
      }
return (
    <FlipSliderStyles setHeight={300}>
        <div className='demoslider-container' id="projects" >
            <h1 className={isDark ? "dark-mode demoslider-title" : "demoslider-title"}>Portfolio Preview</h1>
            <div 
                className={ isDark ? "media-scroller snaps-inline dark-mode" : "media-scroller snaps-inline light-mode" }
                id={ isDark ? "media-scroller-dark-mode" : "media-scroller-light-mode" }
            >
                <FlipCard frontside={newcard01} backside={newcard06} borderBack/>
                <FlipCard frontside={newcard02} backside={newcard04} borderBack/>
                <FlipCard frontside={newcard05} backside={newcard07} borderBack/>
                <FlipCard frontside={newcard03} backside={newcard03} borderFront/>
                <FlipCard frontside={newcard04} backside={newcard02} borderFront/>
                <FlipCard frontside={newcard06} backside={newcard01} borderFront/>
                <FlipMe />
                <FlipCard frontside={newcard07} backside={newcard05} borderFront/>
        </div>
      </div>
    </FlipSliderStyles>
  )
}

export default FlipSlider;