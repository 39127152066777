import React from "react";
import Header from "../components/header/Header";
import Greeting from "./greeting/Greeting";

import DevSkills from "./skills/DevSkills";

import Projects from "./projects/Projects";
import FlipSlider from "../components/slider/FlipSlider";
import Footer from "../components/footer/Footer";
import Contact from "./contact/Contact";
import Education from "./education/Education";
import Work from "./work/Work";

import ScrollToTopButton from "./scrollToTopButton/ScrollToTopButton";
import AnimatedCoder from "../components/animatedCoder/AnimatedCoder";

import {StyleProvider} from "../contexts/StyleContext";
import {useLocalStorage} from "../hooks/useLocalStorage";
import SiteContentStyles from "./SiteContentStyles";

const SiteContent = () => {
  const darkPref = window.matchMedia("(prefers-color-scheme: dark)");
  const [isDark, setIsDark] = useLocalStorage("isDark", darkPref.matches);

  const changeTheme = () => {
    setIsDark(!isDark);
  };

  const OnScrolling = () => {    
    if(document.querySelector(".site-wrapper").scrollTop > 550) {
      document.getElementById("topButton").style.visibility = "visible";
    } else {
      document.getElementById("topButton").style.visibility = "hidden";
    }
  }

return (
    <SiteContentStyles>
      <div 
        className={isDark ? "dark-mode site-wrapper" : "site-wrapper"}
        onScroll={() => OnScrolling()}
      >
        <StyleProvider value={{isDark: isDark, changeTheme: changeTheme}}>
        <div 
          className={isDark ? "dark-mode" : "light-mode"} 
          style={{ position: 'fixed', zIndex: '1000' }}
        >
          <Header />     
        </div>
          <AnimatedCoder customClass={isDark}/>
          <Greeting />
          <DevSkills />
          <Education />
          <Work />
          <Projects />
          <FlipSlider />
          <Contact />
          <Footer />
          <ScrollToTopButton isDark={isDark}/>
        </StyleProvider>
      </div>
    </SiteContentStyles>
  );

};

export default SiteContent;