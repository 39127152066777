import styled from "styled-components";

const DevSkillsStyles = styled.div`

.main {
  position: relative;
  width: clamp(320px,70vw,1200px);
  margin: auto;
}
.skills-main-div {
  display: flex;
  width: 100%;
  overflow: hidden;
  padding-top: 30px;
}
.skills-image-div {
  position: relative;
  max-width:380px;
  z-index: 10;
}
#skill * {
  margin-left: 50px;
}
.bottomText,
.skills-main-div * {
  color: #645beb !important;
}
.dark-mode.bottomText,
.dark-mode * {
  color: rgba(255,255,0,0.75) !important;
}
.skills-main-div h1 * {
  font-size: clamp(16px,3vw,30px);
}
.skills-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}
.skills-image-div > img {
  max-width: 100%;
  height: auto;
  z-index: 10;
}
.hide {
  position: absolute;
  opacity: 0.01;
  z-index: -10;
}
.show {
  opacity: 1;
  z-index: 10;
}
.skills-heading {
  padding-top: clamp(30px, 10vw,110px);
  font-size: clamp(20px, 10vw, 50px);
}
.more-skills-heading {
  font-size: clamp(26px,4vw,42px);
  line-height: clamp(26px,4vw,42px);
}
@media (max-width: 768px) {
  .skills-main-div {
    flex-direction: column;
  }
  .skills-text-div {
    margin-left: 0px;
    margin: 20px;
  }
  .skills-image-div {
    order: 2;
  }
}
`;

export default DevSkillsStyles;