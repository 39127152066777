import styled from "styled-components";

const AnimatedIconBGStyles = styled.div`

    .icon-bg {
        position: absolute;
        top: 5%;
        left: -20%;
        z-index: 1;
    }
    section {
        position: relative;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        width: 100%;
        padding: 5vh 0vw;
    }

    section .row {
        position: relative;
        top: 0%;
        width: 100%;
        display: flex;
        padding: 10px 0;
        white-space: nowrap;
        font-size: 60px;
        transform: rotate(-1.5deg);
    }

    section .row div {
        animation: animate1 70s linear infinite;
        animation-delay: -70s;
    }
    section .row div:nth-child(2) {
        animation: animate2 70s linear infinite;
        animation-delay: -35s;
    }
    @keyframes animate1 {
        0%   { transform: translateX(100%) }
        100% { transform: translateX(-100%) }
    }
    @keyframes animate2 {
        0%   { transform: translateX(0%) }
        100% { transform: translateX(-200%) }
    }



    section .row:nth-child(even) div {
        animation: animate3 60s linear infinite;
        animation-delay: -60s;
    }
    section .row:nth-child(even) div:nth-child(2) {
        animation: animate4 60s linear infinite;
        animation-delay: -30s;
    }
    @keyframes animate3 {
        0%   { transform: translateX(-100%) }
        100% { transform: translateX(100%) }
    }
    @keyframes animate4 {
        0%   { transform: translateX(-200%) }
        100% { transform: translateX(0) }
    }


    i {
        /* color: #fff; */
        color: rgba(0,0,0,0.5);
        transition: 0.35s;
        padding: 0 5px;
        user-select: none;
        cursor: default;
    }

    i:hover {
        color: white;
        text-shadow: 0 0 120px white;
        transition: 0s;
    }

    #lightmode, 
    svg#lightmode, 
    #lightmode svg,
    svg 
    {
        width: 60px;
        box-sizing: border-box;
        fill: rgba(101, 91, 235, 0.15) !important;
        transition: 0.5s;
        padding: 0 5px;
        user-select: none;
        cursor: default;
    }
    #darkmode, 
    svg#darkmode, 
    #darkmode svg {
        width: 60px;
        box-sizing: border-box;
        fill: rgba(255, 255, 0, 0.15) !important;
        transition: 0.5s;
        padding: 0 5px;
        user-select: none;
        cursor: default;
    }

    #lightmode.express *, #lightmode.astro *, 
    #lightmode.pwa *, #lightmode svg *, svg#lightmode *, 
    .express *, .astro *, .pwa *, svg *
    { 
        fill: rgba(101, 91, 235, 0.15);
        transition: 0.5s;
        padding: 0 5px;
        user-select: none;
        cursor: default;
        width: 60px; 
        height: 60px;
    }
    #lightmode.express:hover *, #lightmode.astro:hover *, #lightmode.pwa:hover *, 
    #lightmode.bbo:hover *, #lightmodesvg:hover *, #lightmode svg path:hover *
    { 
        fill: rgba(101, 91, 235, 0.35) !important; 
        box-shadow: 0 0 120px rgba(101, 91, 235, 0.15);
        text-shadow: 0 0 120px rgba(101, 91, 235, 0.15);
        transition: 0s;
    }

    #darkmode.express *, #darkmode.astro *, #darkmode.pwa *,
    #darkmode svg *,#darkmodesvg *,
    #darkmode * {
        fill: rgba(255, 255, 0, 0.075) !important;
        transition: 0.5s !important;
        padding: 0 5px !important;
        user-select: none !important;
        cursor: default !important;
        width: 60px !important; 
        height: 60px !important;
    }
    #darkmode:hover * {
        fill: rgba(255, 255, 0, 0.35) !important;
        box-shadow: 0 0 120px rgba(255, 255, 0, 0.15) !important;
        text-shadow: 0 0 120px rgba(255, 255, 0, 0.15) !important;
        transition: 0s !important;
    }

`;

export default AnimatedIconBGStyles;