import React from "react";
import {useContext} from "react";
import StyleContext from "../../contexts/StyleContext";

import DisplayLottie from "../displayLottie/DisplayLottie";

import Coding_Light from '../../assets/lottie/Coding_Light.json';
import Coding_Dark from '../../assets/lottie/Coding_Dark.json';

const CoderAnimation = () => {
    const {isDark} = useContext(StyleContext);

  return (
    <div className="coder-animation-wrapper">
        <div className={`${isDark ? "coder display-none" : "coder"}`} id="coder-light">
            <DisplayLottie animationData={Coding_Light} />
        </div>
        <div className={`${isDark ? "coder" : "coder display-none"}`} id="coder-dark">
            <DisplayLottie animationData={Coding_Dark} />
        </div>
    </div>
  );
}

export default CoderAnimation;
