import styled from 'styled-components';

const FlipSliderStyles = styled.div`
    .demoslider-container {
        position: relative;
        height: 300;
        width: 100%;
        padding: 2vh 5vw;
        margin-bottom: 5vh;
    }
    .container {
        margin-inline: auto;
    }
    .flow {
        display: grid;
        gap: 1rem;
    }
    .page-header {
        padding-block: 4rem;
        margin-block-end: 4rem;
        background: radial-gradient(circle at 50% -250%,#374151,#111827,#000);
        color: #f8f9fa;
        box-shadow: 0 3px 5px -2px hsl(220 3% 15%/calc(1% + 3%)),0 7px 14px -5px hsl(220 3% 15%/calc(1% + 5%));
    }
    .demoslider-title {
        padding-top: 90px;
        color: #645beb;
        margin-bottom: 0px;
    }
    .dark-mode.demoslider-title {
        color: yellow !important;
    }
    .page-subtitle {
        font-size: clamp(1rem,4vw,1.5rem);
    }
    .section-title {
        padding-inline-start: 1.75rem;
        color: #F1F1F4;
        padding-left: 50px;
        padding-top: 25px;
        font-size: clamp(15px, 7vw, 40px);
    }
    .media-scroller {
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: 200px;
        grid-column: auto;
        gap: 50px;
        padding: 0vh 1vw 10vh;
        overflow-x: auto;
        overscroll-behavior-inline: contain;
        color: white;
        height: 100%;
        cursor: pointer;
    }
    .media-scroller--with-groups {
        grid-auto-columns: 80%;
    }
    .media-group {
        display: grid;
        gap: 1rem;
        grid-auto-flow: column;
    }
    .media-scroller::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);  
        width: 90vw;
    }
    .media-scroller::-webkit-scrollbar {
        background-color:rgb(39,48,56);
    }
    .media-scroller::-webkit-scrollbar-thumb {
        background-color: rgb(216, 216, 21);
        border-color: rgb(39, 48, 56);
        border-radius: 5px;
    }
    #media-scroller-light-mode::-webkit-scrollbar-thumb {
        background: #645beb;
        border: 3px solid black;
    }
    #media-scroller-dark-mode::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 0, 0.75);
        border: 3px solid black;
    }
    .media-element {
        position: relative;
        display: grid;
        /* background: rgb(206 190 190); */
        border-radius: 20px;
        box-shadow: 20px 30px 5px -2px hsl(220 3% 15%)/calc(10% + 3%);
        height: ${props => props.setHeight}px;
        aspect-ratio: 6 / 9;

        padding: 10px;
        margin-bottom: 0px;
        margin-top: 70px;
        user-select: none;
        background-color: #645BEB;
    }
    .media-element.dark-mode {
        background: rgba(255, 255, 0, 0.75);
    }
    #darkborder {
        border: 12px solid black !important;
        position: relative;
        top: -15px;
        left: -11px;
        height: calc(100% - 25px);
        width: calc(100% + 5px);
    }
    .media-element.light-mode {
        background-color: #2196f3;
    }
    .media-element > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
    .side > img {
        height: 100%;
        width: 100%;
        background-size: cover;
        border-radius: 20px;
        overflow: hidden;
    }
    .snaps-inline {
        scroll-snap-type: inline mandatory;
        scroll-padding-inline: 1rem;
    }
    .snaps-inline > * {
        scroll-snap-align: start;
    }


    .flip-card {
        background-color: transparent;
        height: 300px;
        perspective: 1000px;
        color: #645BEB;
        aspect-ratio: 6 / 9;
        font-size: 14px;
        margin-bottom: 10vh;
    }
    .dark-mode.flip-card {
        color: rgba(255, 255, 0, 0.75) !important;
    }

    .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
    }

    .side {
        border-radius: 20px;
    }

    .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
    }

    .flip-card-front, .flip-card-back {
        position: absolute;
        display: grid;
        /* position: relative; */
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        background-color: #645BEB;
        padding: 10px;
        margin-bottom: 0px;
        margin-top: 70px;
        user-select: none;
        background-color: #645BEB;
        border-radius: 20px;
    }
    .flip-card-back-content {
        background-color: black;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .flip-card-back-content h1 {
        font-size: 30px;
        font-weight: 700;
    }

    .flip-card-back {
        transform: rotateY(180deg) translateX(20px);
    }
    .dark-mode.flip-card-front,
    .dark-mode.flip-card-back {
        background: rgba(255, 255, 0, 0.75);
        color: rgba(255, 255, 0, 0.55);
    }

    #border {
        border: 12px solid black !important;
        position: relative;
        top: -14px;
        left: -11px;
        height: 100%;
        width: 100%;
    }
    
    #dark-text {
        color: yellow;
    } 

`;

export default FlipSliderStyles;