import React from "react";
import {useContext} from "react";
import StyleContext from "../../contexts/StyleContext";

import "./Work.css";
import {workInfo} from "../../portfolio";

export default function Work() {
  const {isDark} = useContext(StyleContext);

  if (workInfo.display) {
    return (
      <div className="work-section" id="work">
        <h1 className="work-heading work-section-header">Dev Job Experience</h1>
        <div className={isDark ? "dark-mode work-card-border" : "work-card-border"}></div>
        <div className={isDark ? "dark-mode last-work-container" : "last-work-container"}>
          <div className="gleipnir">
            <div className="gleipnir-text">
              <p>
                Gleipnir.org had the idea to create a platform for Icelandic students where 
                learning would be gamified to make it more fun and at the same time also give 
                teachers better overview and instant feedback with statistics of student’s performance.
              </p>
              <p>
                This was a start-up-project funded for 6 months and I was fortunate to be part of 
                the team of 8 programmers selected, where I became the lead UI/UX designer and programmer.
              </p>
              <p>
                The objective was to develop a game-like quiz app for students of all ages yet with special focus 
                on the primary education stage (8-16 years old kids).
              </p>
              <p>
                During this time got the opportunity to take part in all stages of web development, 
                beginning with brainstorming features and functionality, creating wireframes & designs in Figma, 
                then selecting the tech-stack for the project and finally programming and handing off a working MVP prototype.
              </p>
              <p>
                <b>Project tech-stack:</b> ReactJS with Material-UI & styled-components for the frontend 
                and .NET/C#/ASP with IdentityFramework and Azure DB for the backend.
              </p>
            </div>
            <div className="gleipnir-img" />
          </div>
        </div>
      </div>
    );
  }
  return null;
}
