import React from "react";
import {useContext} from "react";
import StyleContext from "../../contexts/StyleContext";

import "./Education.css";
import EducationCard from "../../components/educationCard/EducationCard";
import {educationInfo} from "../../portfolio";

export default function Education() {
  const {isDark} = useContext(StyleContext);

  if (educationInfo.display) {
    return (
      <div className="education-section" id="education">
        <h1 className={isDark ? "dark-mode work-heading" : "work-heading"}>Education</h1>
        <div className="work-card-container">
          {educationInfo.schools.map((school, index) => (
            <EducationCard key={index} school={school} />
          ))}
        </div>
      </div>
    );
  }
  return null;
}
