import React from 'react'
import IconRow01 from './IconRow01';
import IconRow02 from './IconRow02';

import AnimatedIconBGStyles from './AnimatedIconBGStyles';

const AnimatedIconBG = () => {

  return (
    <AnimatedIconBGStyles>
      <div className="icon-bg">
      <section>
        <IconRow01 />
        <IconRow02 />
      </section>
      </div>
    </AnimatedIconBGStyles>
  )
}

export default AnimatedIconBG;